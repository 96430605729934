<p class="forgot-password-message">
  {{'Aut_EnterEmailForPasswordReset' | translate}}
</p>
<form [formGroup]="resetPasswordForm"
      name="resetPassword"
      class="form-horizontal margin-top-0"
      role="form"
      (submit)="submit()"
      novalidate
      autocomplete="off"
      id="resetPasswordForm">
  <div class="email-input-container">
    <div class="email-input-group">
      <span class="email-input-icon"><fa-icon [icon]="faEnvelope"></fa-icon></span>
      <input type="email"
             id="emailAddress"
             name="emailAddress"
             class="email-input"
             [ngClass]="{'input-validation' : showValidation}"
             placeholder="{{'Aut_EmailAddress' | translate}}"
             formControlName="emailAddress"
             required>
    </div>
    <div *ngIf="resetPasswordForm.get('emailAddress').invalid && showValidation" class="validation-error-msg">
      <span *ngIf="resetPasswordForm.get('emailAddress').hasError('required')">{{'Aut_EnterEmail' | translate}}</span>
      <span *ngIf="resetPasswordForm.get('emailAddress').hasError('maxlength')">{{'Aut_EmailMaxLength' | translate}}</span>
      <span *ngIf="resetPasswordForm.get('emailAddress').hasError('email')">{{'Aut_EmailInvalidFormat' | translate}}</span>
    </div>
  </div>
  <div class="buttons-container">
    <button type="submit" class="reset-password-button" id="resetBtn">{{'Aut_Reset' | translate}}</button>
    <a routerLink="/" routerLinkActive="active" queryParamsHandling="merge" class="cancel-button" id="cancelBtn">{{'Aut_Cancel' | translate}}</a>
  </div>
</form>
