import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayoutEventNotificationService, LocalisationService, Language } from '@keystone-angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
    activeLanguage: Language;
    availableLanguages: Language[];
    sessionTimeout$: Observable<boolean>;
    showMigratedInfo: boolean;
    showSpinner: boolean;

    private httpRequestInProgressSubscription: Subscription;
    private timeoutInstance: any;

    constructor(private activatedRoute: ActivatedRoute,
                private layoutEventNotificationService: LayoutEventNotificationService,
                private localisationService: LocalisationService) { }

    ngOnDestroy(): void {
        // tslint:disable-next-line: no-unused-expression
        this.httpRequestInProgressSubscription && this.httpRequestInProgressSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.activeLanguage = this.localisationService.getActiveLanguage();
        this.availableLanguages = this.localisationService.getLanguages();
        localStorage.clear();

        this.sessionTimeout$ = this.activatedRoute
            .queryParamMap
            .pipe(map(params => params.has('sessionTimeout')));

        this.httpRequestInProgressSubscription = this.layoutEventNotificationService.httpRequestInProgress
            .subscribe((isLoading: boolean) => {
                this.showSpinner = isLoading;

                if (this.timeoutInstance) {
                    clearTimeout(this.timeoutInstance);
                }

                if (isLoading) {
                    this.timeoutInstance = setTimeout(() => {
                        this.showSpinner = false;
                    }, 6000);
                }
            });

        this.showMigratedInfo = window.location.search === '?isRedirected=1';
    }

    onLanguageChange(language: Language): void {
        this.localisationService.changeLanguage(language).subscribe(() => { });
    }
}
