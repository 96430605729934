import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';

@Directive({
  selector: '[appIfAdminLogin]'
})
export class IfAdminLoginDirective {
  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    if (!environment.production || window.location.href === environment.adminLoginUrl) {
      viewContainer.createEmbeddedView(templateRef);
    } else {
      viewContainer.clear();
    }
  }
}
