import { Injectable } from '@angular/core';
import { TokenService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationDataService, LogInResponse } from './authentication-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationManager {
  redirectUrl: string;

  constructor(private authenticationDataService: AuthenticationDataService,
              private tokenService: TokenService) { }

  addUser(user: any): Observable<any> {
    return this.authenticationDataService.addUser(user);
  }

  confirmUserNameChange(token: any): Observable<any> {
    return this.authenticationDataService.confirmUserNameChange(token);
  }

  changePassword(resetToken: string, password: string): Observable<any> {
    return this.authenticationDataService.changePassword({
      token: resetToken,
      newPassword: password,
      passwordConfirmation: password
    });
  }

  getPersonInfo(token: any): Observable<any> {
    return this.authenticationDataService.getPersonInfo(token);
  }

  getStores(): Observable<any> {
    return this.authenticationDataService.getStores();
  }

  logIn(credentials: any): Observable<LogInResponse> {
    return this.authenticationDataService.logIn(credentials).pipe(map(response => {
      this.tokenService.setToken(response.body.token);

      return response.body;
    }));
  }

  microsoftLogIn(accessToken: string): Observable<any> {
    return this.authenticationDataService.microsoftLogIn(accessToken).pipe(map(response => {
      this.tokenService.setToken(response.body.token);

      return response.body;
    }));
  }

  logOut(): Observable<void> {
    return this.authenticationDataService.logOut().pipe(map(() => {
      this.tokenService.removeToken();
    }));
  }

  refreshToken(): Observable<any> {
    return this.authenticationDataService.refreshToken().pipe(map(response => {
      this.tokenService.setToken(response.body.token);

      return response.body;
    }));
  }

  resetPassword(emailAddress: string): Observable<any> {
    return this.authenticationDataService.resetPassword(emailAddress).pipe(map(response => response.data));
  }
}
