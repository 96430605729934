import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
    {
        path: '',
        children: [
            /*{
                component: ChangePasswordComponent,
                path: 'change'
            },
            {
                component: ConfirmUserNameComponent,
                path: 'confirm'
            },
            {
                component: UpdatePreferencesComponent,
                path: 'gdpr'
            },*/
            {
                component: LoginComponent,
                path: ''
            },
            /*{
                component: RegisterUserComponent,
                path: 'register'
            },*/
            {
                component: ResetPasswordComponent,
                path: 'reset'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
