<ng-container *ngIf="showSpinner">
  <div class="backdrop"></div>
  <key-spinner class="spinner"></key-spinner>
</ng-container>

<form name="loginForm"
      class="login-form"
      role="form"
      (submit)="submit()"
      novalidate
      autocomplete="off"
      id="submitLoginForm"
      [formGroup]="loginForm" *appIfNormalLogin>
  <div class="email-input-container">
    <div class="email-input-group">
      <span class="email-input-icon">
        <fa-icon [icon]="faEnvelope"></fa-icon>
      </span>
      <input type="email"
             name="userName"
             id="userName"
             class="email-input"
             [ngClass]="{'input-validation' : showValidation}"
             placeholder="{{'Aut_EmailAddress' | translate}}"
             formControlName="userName"
             required>
    </div>
    <div *ngIf="loginForm.get('userName').invalid && showValidation" class="validation-error-msg">
      <span *ngIf="loginForm.get('userName').hasError('required')">{{'Aut_EnterEmail' | translate}}</span>
      <span *ngIf="loginForm.get('userName').hasError('maxlength')">{{'Aut_EmailMaxLength' | translate}}</span>
      <span *ngIf="loginForm.get('userName').hasError('email')">{{'Aut_EmailInvalidFormat' | translate}}</span>
    </div>
  </div>
  <div class="password-input-container">
    <div class="password-input-group">
      <span class="password-input-icon">
        <fa-icon [icon]="faKey"></fa-icon>
      </span>
      <input type="password"
             id="password"
             name="password"
             class="password-input" [ngClass]="{'input-validation' : showValidation}"
             placeholder="{{'Aut_Password' | translate}}"
             formControlName="password"
             required>
    </div>
    <div *ngIf="loginForm.get('password').invalid && showValidation" class="validation-error-msg">
      <div *ngIf="loginForm.get('password').hasError('required')">{{'Aut_EnterPassword' | translate}}</div>
    </div>
    <span class="forgot-password">
      <a routerLink="/reset" routerLinkActive="active" queryParamsHandling="merge">{{'Aut_ForgotPassword' | translate}}</a>
    </span>
  </div>
  <div class="login-button-container">
    <button type="submit" class="login-button" id="logInBtn">{{'Aut_LogIn' | translate}}</button>
  </div>
</form>

<button type="button" class="idp-login-button" (click)="microsoftLogin()">
  <div class="microsoft-logo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{'Aut_MicrosoftLogin' | translate}}</div>
</button>
