import { registerLocaleData } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';
import localeNb from '@angular/common/locales/nb';
import { LocalisationService, ServerSettingsManagerService } from '@keystone-angular/core';

export function appInitialization(localisationService: LocalisationService,
                                  serverSettingsManager: ServerSettingsManagerService) {
    return () => Promise.all([
      serverSettingsManager.loadServerSettings().toPromise(),
      localisationService.loadLanguages().toPromise().then(() => {
            // TODO: We may do this dinamically
            // Check: https://blog.angularindepth.com/dynamic-import-of-locales-in-angular-b994d3c07197
            switch (localisationService.getActiveLanguage().code) {
                case 'en-GB':
                    registerLocaleData(localeGb, 'en-GB');
                    break;
                default:
                    registerLocaleData(localeNb, 'nb-NO');
            }

            return localisationService.loadLocalisation().toPromise();
        })
    ]);
}
