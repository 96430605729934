import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';

@Directive({
  selector: '[appIfNormalLogin]'
})
export class IfNormalLoginDirective {
  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    if (window.location.href !== environment.adminLoginUrl) {
      viewContainer.createEmbeddedView(templateRef);
    } else {
      viewContainer.clear();
    }
  }
}
