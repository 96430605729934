import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NotificationService, TranslatePipe } from '@keystone-angular/core';
import { AuthenticationManager } from '../authentication-manager.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    faEnvelope = faEnvelope;
    resetPasswordForm: FormGroup;
    showValidation: boolean;

    constructor(private authenticationManager: AuthenticationManager,
                private formBuilder: FormBuilder,
                private notificationService: NotificationService,
                private translatePipe: TranslatePipe) { }

    ngOnInit(): void {
        this.resetPasswordForm = this.formBuilder.group({
            emailAddress: ['', [Validators.email, Validators.maxLength(50), Validators.required]]
        });
    }

    submit(): void {
        this.showValidation = false;

        const emailAddress = this.resetPasswordForm.get('emailAddress').value;
        if (this.resetPasswordForm.valid) {
            this.authenticationManager.resetPassword(emailAddress).subscribe(() => {
                this.notificationService.showSuccess(this.translatePipe.transform('Aut_PasswordResetLinkSent'));
            });
        } else {
            this.showValidation = true;
        }
    }
}
