import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { NotificationService, TranslatePipe } from '@keystone-angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationManager } from '../authentication-manager.service';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  faEnvelope = faEnvelope;
  faKey = faKey;
  loginForm: FormGroup;
  redirectPath: string;
  showValidation: boolean;
  showSpinner: boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private authenticationManager: AuthenticationManager,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private translatePipe: TranslatePipe,
    private msalAuthService: MsalService) { }

  ngOnInit(): void {
    if (window.location.hostname === 'admin-login.frontsystems.com') {
      const newUrl = window.location.href.replace('.com', '.no');
      window.location.replace(newUrl);
    }

    this.handleRedirectToPortal();

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.redirectPath = queryParams.get('redirectPath') || '';
    });

    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      userName: ['', [Validators.email, Validators.maxLength(50), Validators.required]]
    });
  }

  microsoftLogin(): void {
    this.msalAuthService.instance.loginRedirect({ scopes: [''], prompt: 'select_account' });
  }

  submit(): void {
    this.showValidation = false;

    if (this.loginForm.valid) {
      const credentials = this.loginForm.value;

      this.showSpinner = true;
      this.authenticationManager.logIn(credentials).subscribe(() => {
        this.showSpinner = false;
        location.assign(`${environment.portalApp}/${this.redirectPath}`);
      }, result => {
        this.handleLoginError(result);
      });
    } else {
      this.showValidation = true;
    }
  }

  handleLoginError(result: any): void {
    this.showSpinner = false;
    if (result.error && result.error.reason) {
      const reason = result.error.reason;

      switch (reason) {
        case 'IncorrectMailOrPassword':
          this.notificationService.showError(this.translatePipe.transform('App_IncorrectMailOrPassword'));
          break;
        case 'HasNoAccess':
          this.notificationService.showError(this.translatePipe.transform('App_HasNoAccess'));
          break;
        case 'IdpAuthenticationOnly':
          this.notificationService.showError(this.translatePipe.transform('App_IdpOnlyAuthentication'));
          break;
        case 'InactiveAccount':
          this.notificationService.showError(this.translatePipe.transform('App_InactiveAccount'));
          break;
        case 'OnlyMicrosoftWorkAccounts':
          this.notificationService.showError(this.translatePipe.transform('App_OnlyMicrosoftWorkAccounts'));
          break;
        default:
          if (result.statusText && result.statusText.length > 0) {
            this.notificationService.showError(result.statusText);
          }
      }
    } else if (result.status == 500) {
      this.notificationService.showError(this.translatePipe.transform('App_HasNoAccess'));
    } else if (result.statusText && result.statusText.length > 0) {
      this.notificationService.showError(result.statusText);
    }
  }

  handleRedirectToPortal(): void {
    this.msalAuthService.instance.handleRedirectPromise().then(authResult => {
      if (authResult.account && authResult.accessToken) {
        this.showSpinner = true;
        this.authenticationManager.microsoftLogIn(authResult.accessToken).subscribe(() => {
          location.assign(`${environment.portalApp}/${this.redirectPath}`);
        }, error => {
          this.handleLoginError(error);
        });
      }
    }).catch(err => {
      this.handleLoginError(err);
    }); 
  }
}
