import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  LanguagePickerModule, LocalisationService, ServerSettingsManagerService,
  SpinnerModule, TranslateModule, TranslatePipe
} from '@keystone-angular/core';
import { appInitialization } from './app-initialization.function';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { IfAdminLoginDirective } from './if-admin-login.directive';
import { IfNormalLoginDirective } from './if-normal-login.directive';

import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from "@azure/msal-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    IfAdminLoginDirective,
    IfNormalLoginDirective
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    LanguagePickerModule,
    ReactiveFormsModule,
    SpinnerModule,
    TranslateModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: '1cd842f8-fd73-4e7b-9d03-47ddded0b5a4',
        authority: 'https://login.microsoftonline.com/common/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect // MSAL Guard Configuration
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([])// MSAL Interceptor Configuration
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialization,
      deps: [LocalisationService, ServerSettingsManagerService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [LocalisationService],
      useFactory: (localisationService: LocalisationService) => localisationService.getActiveLanguage()
    },
    TranslatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
