<div class="app-container">
  <key-spinner *ngIf="showSpinner" class="full-window"></key-spinner>
  <key-language-picker [activeLanguage]="activeLanguage"
                       [languages]="availableLanguages"
                       (languageChange)="onLanguageChange($event)"></key-language-picker>
  <div class="login-panel">
    <div class="login-panel-heading">
      <h4 class="login-panel-logo-container">
        <img id="logo" src="/images/front_logo_original_blue.png" height="50" alt="{{'App_Keystone' | translate}}">
      </h4>
      <hr class="login-panel-heading-separator" />
      <div class="alert alert-info" *ngIf="showMigratedInfo">
        <strong>{{'App_VelkommentText'|translate}}</strong>
        <br />
        {{'App_RedirectedMessage'|translate}}
      </div>
      <div class="alert alert-info" *ngIf="!showMigratedInfo && (sessionTimeout$ | async)">
        {{'App_SessionTimeout'|translate}}
      </div>
    </div>
    <div class="login-panel-body">
      <router-outlet></router-outlet>
    </div>
  </div>
  <p class="copyright-text">Front Systems AS</p>
</div>
